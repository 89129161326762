import React from 'react'
import styled from 'styled-components'
import { SMALL } from './media_breakpoints'

const Container = styled.div`
  div {
    margin: 0 0 16px 0;
    
    @media (max-width: ${SMALL}) {
      margin: 0 0 8px 0;
    }
  }

  a {
    display: block;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 3px;
    text-align: center;
  }
  
  p {
    font-weight: 700;
    text-align: justify;
  }
`

const ScaledImage = styled.img`
  height: auto;
  width: 100%;
`

interface Props {
  url: string,
  name: string,
  description: string,
  repo: string,
  screenshot: string,
}

export default function ProjectCard (props: Props) {
  const { url, name, description, repo, screenshot } = props

  return (
    <Container>
      <div><a href={ url }>{ name }</a></div>
      <ScaledImage src={ screenshot } alt={ `a screenshot of the website ${name}` }/>
      <p>{ description }</p>
      <hr />
      <div><a href={ repo }>{ repo }</a></div>
    </Container>
  )
}