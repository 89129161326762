import React from 'react'
import { render } from 'react-dom'
import styled, { createGlobalStyle } from 'styled-components'
import '../newrelic.js'
import ProjectCard from './project_card'
import { SMALL } from './media_breakpoints'
import DESCRIPTIONS from './projects/descriptions'

const BIC_LIGHTER_BLUE = '#68bdd4'
const CHARCOAL_BLACK = '#222'

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  html, body, #app {
    background-color: ${BIC_LIGHTER_BLUE};
    color: ${CHARCOAL_BLACK};
    font-family: "Lato", sans-serif;
    min-height: 100vh;
    position: relative;
    margin: 0;
    padding: 0;
  }
  
  a, a:hover, a:active, a:visited {
    color: ${CHARCOAL_BLACK};
    text-decoration: none;
  }
`

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
`

const FlexUl = styled.div`
  background-color: ${CHARCOAL_BLACK};
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  margin: 0;
  width: 80%;
  
  @media (max-width: ${SMALL}) {
    width: 100%;
  }

  li {
    background-color: ${BIC_LIGHTER_BLUE};
    flex: 0 1 30%;
    list-style-type: none;
    margin: 16px 0;
    padding: 16px;
    
    @media (max-width: ${SMALL}) {
      flex: 0 1 calc(50% - 8px); 
      font-size: 14px;
      padding: 8px;
      margin: 4px 0;
    }
  }
`

render(
  <Container>
    <GlobalStyle/>
    <FlexUl>
      {
        DESCRIPTIONS.map(project => <li><ProjectCard url={`https://${project.name}.stxalq.dev`} name={ project.name } description={ project.description } repo={ project.repo } screenshot={project.screenshot}/></li>)
      }
    </FlexUl>
  </Container>
  , document.getElementById('app')
)
