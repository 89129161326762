import AutoPublicist from './screenshots/auto-publicist.png'
import KomaKulshan from './screenshots/koma-kulshan.png'
import Ktmap from './screenshots/ktmap.png'
import Numeronym from './screenshots/numeronym.png'
import Qyam from './screenshots/qyam.png'
import Skwist from './screenshots/skwist.png'

const GITHUB_USER_URL = 'https://github.com/worc'

export default [
  {
    name: 'auto-publicist',
    description: 'took this from a weird image somewhere that was one of those "use the first and last letter of your name" games to generate a random pitch for a book or whatever. it seemed ripe for just turning into a random generator, so here we are.',
    repo: 'https://github.com/worc/auto-publicist.git',
    screenshot: AutoPublicist,
  },
  {
    name: 'koma-kulshan',
    description: 'another bit of thievery. i scraped a bunch of minor magic effects for dnd items from some BC teacher\'s website and turned it into a generator. what i really wanted was the ability to only change parts of the item once it settled in. koma-kulshan makes use of my text scrambler, bamboozle.',
    repo: 'https://github.com/worc/koma-kulshan',
    screenshot: KomaKulshan,
  },
  {
    name: 'ktmap',
    description: `i made a trimet app that will ask for your location and then tell you your nearest trimet stops, because all the cool kids do it at some point. the stops themselves will print out the next arrivals which is pretty handy. really i wrote this because all the other apps are slow to boot and google's transit maps are off sometimes. one weird bug: this one will crash hard if you're not close to the portland area.`,
    repo: `${GITHUB_USER_URL}/ktmap`,
    screenshot: Ktmap,
  },
  {
    name: 'numeronym',
    description: `this is an entire static website dedicated to one very simple script that turns words into their numeronym forms. that's it. that's all it does. what more do you want?`,
    repo: `${GITHUB_USER_URL}/numeronym`,
    screenshot: Numeronym,
  },
  {
    name: 'qyam',
    description: `i wanted a quick and easy way to convert text to saracsm, vaporwave, and zalgo text. so here you go. also you can apply all three at the same time which i think is pretty cool.`,
    repo: `${GITHUB_USER_URL}/qyam`,
    screenshot: Qyam,
  },
  {
    name: 'skwist',
    description: `i've lost the source code to this one, luckily there are source maps on the host. basically it's just name generator. it's pulled from some site i'll have to find and credit. again, one of those things where i thought the original was a good idea but it needed better execution in the source code and needed to be made open and publicly available. and i wanted it to run faster on my phone so i could use it for monster of the week.`,
    repo: `${GITHUB_USER_URL}/skwist`,
    screenshot: Skwist,
  }
]
